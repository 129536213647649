import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";


const style = {
    grid: {
        padding: "0 5px !important",
    }
};

function WhatsApp({ classes, PhoneCode, Phone, ...rest }) {
    const [countdown, setCountdown] = useState(0); // State to manage the countdown
    const formattedPhoneCode = PhoneCode === 91 ? "91" : "";
    const formattedPhone = Phone ? Phone.toString().replace("+", "") : "";
    let whatsappLink = `whatsapp://send?phone=${formattedPhoneCode}${formattedPhone}`;
    
    const handleWhatsAppClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        if (isMobile) {
            whatsappLink = `whatsapp://send?phone=${formattedPhoneCode}${formattedPhone}`;
        } else {
            whatsappLink = `https://api.whatsapp.com/send?phone=${formattedPhoneCode}${formattedPhone}`;
        }
         
        // Start the countdown
        setCountdown(3);
        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(interval); // Stop the countdown when it reaches 0

                    // Use window.location.href to navigate to the WhatsApp link
                    //window.open(whatsappLink, "_blank", "noopener,noreferrer"); // Open WhatsApp in a new tab
                    window.location.href = whatsappLink; // Redirect to WhatsApp link
                    // setTimeout(() => {
                    //     window.history.forward(); // Navigate to the next page
                    // }, 1000);
                    
                }
                return prev - 1;
            });
        }, 1000); // Update every second

        return false; // Prevent default action
    };

    return (
        <Button
            justIcon={true}
            onClick={handleWhatsAppClick}
            className="whatsapp"
            style={{ cursor: 'pointer', position: 'relative' }}
        >
            {countdown > 0 ? (
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>{countdown}</span>
            ) : (
                <i className="fa fa-whatsapp" />
            )}
        </Button>
    );
}

export default withStyles(style)(WhatsApp);